<template>
    <div v-loading.fullscreen.lock="loading" class="login-wrapper ">
        <the-header/>
        <div class="user-details-card bg-blur boxHeight_Fix" v-if="currentStep == 0">
            <div class="pricing-details-title">
                Not a member, <a href="https://www.oregonrla.org/membership.html" target="_blank">Click here</a> to join
            </div>
            <div class="mb-lg-4 mb-2 mt-2 mt-lg-0">
                <input type="checkbox" value="memeber-details" v-model="already_member"/>Already a member?
            </div>
            <!-- <div class="input-item my-3" v-if="already_member">
                <label>Member ID: <span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter your Member ID" v-model="member_id"/>
            </div> -->
            <div class="Signup_dataBox">
                <div class="pricing-table">
                    <div class="pricing-table-heading">Courses</div>
                    <div class="pricing-table-heading">Member Pricing</div>
                    <div class="pricing-table-heading">Non-Member Pricing</div>
                    <div class="price-div"></div>
                    <div class="price-div"></div>
                </div>
                <div class="pricing-table" v-for="(courseFolder, index) in coursefolders" :key="index">
                    <div class="course-name">
                        <input type="checkbox" :value="courseFolder.id" v-model="checked_coursefolders"/>
                        <div>
                            {{ courseFolder.coursefolder_name }}
                            <div class="info-text">
                                <img src="/orla/info.png" alt=""/>
                                <div class="course-info">
                                    <ul class="info-list">
                                        <li v-for="(attachedCourses, index) in courseFolder.attachedCourses" :key="index">{{ attachedCourses.name }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="price-div text-start">{{ formatPrice(courseFolder.member_price) }}</div>
                    <div class="price-div text-start">{{ formatPrice(courseFolder.non_member_price) }}</div>
                </div>
    
                <div class="pricing-table" v-for="(currentCourse, index) in course" :key="index">
                    <div class="course-name">
                        <input type="checkbox" :value="currentCourse.id" v-model="checked_courses"/>
                        <div>
                            {{ currentCourse.course_name }}
                        </div>
                    </div>
                    <div class="price-div text-start">{{ formatPrice(currentCourse.member_price) }}</div>
                    <div class="price-div text-start">{{ formatPrice(currentCourse.non_member_price) }}</div>
                </div>
            </div>

            <div class="pricing-details-title mt-3">
                <food-handler/>
            </div>

            <div class="step-buttons mt-3 customStep_BTN">
                <button class="previous-button" type="button" @click="previousStep">
                    Previous
                </button>
                <button class="continue-button" type="button" @click="nextStep">
                    Continue
                    <loading-icon :loading="loading"></loading-icon>
                </button>
            </div>
        </div>

        <div class="user-details-card bg-blur userDetailsCont" v-if="currentStep == 1">
            <div>
                <div class="user-details-title">
                    <p>User Details</p>
                    <div class="d-block">
                        <span class="text-danger">*</span> <span>Indicates a required field.</span> 
                    </div>
                </div>
                
                <div class="user-input-container">
                    <div class="input-item">
                        <label>Company Name </label>
                        <input type="text" placeholder="Enter your Company Name" v-model="employee.company_name"/>
                        <!-- <span v-if="employeeErrors['company_name'] == true" class="text-danger text-sm">The Company Name field is required</span> -->
                    </div>
                    <div class="input-item">
                        <label>First Name <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter your First Name" v-model="employee.first_name"/>
                        <span v-if="employeeErrors['first_name'] == true" class="text-danger text-sm">The First Name field is required</span>
                    </div>
                    <div class="input-item">
                        <label>Last Name <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter your Last Name" v-model="employee.last_name"/>
                        <span v-if="employeeErrors['last_name'] == true" class="text-danger text-sm">The Last Name field is required</span>
                    </div>
                    <div class="input-item">
                        <label>Email <span class="text-danger">*</span></label>
                        <input type="email" placeholder="Enter your Email" v-model="employee.email"/>
                        <span v-if="employeeErrors['email'] == true" class="text-danger text-sm">The Email field is required</span>
                    </div>
                    <div class="input-item" style="position: relative">
                        <label>Password <span class="text-danger">*</span></label>
                        <input type="Password" placeholder="Enter your Password" v-model="employee.password"/>
                        <span v-if="employeeErrors['password'] == true" class="text-danger text-sm">The Password field is required</span>
                    </div>
                    <div class="input-item">
                        <label>Phone <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter your Phone Number" v-model="employee.phone" @input="acceptNumber"/>
                        <span v-if="employeeErrors['phone'] == true" class="text-danger text-sm">The Phone Number field is required</span>
                    </div>
                    <div class="input-item">
                        <label>Address <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter your Address" v-model="employee.address"/>
                        <span v-if="employeeErrors['address'] == true" class="text-danger text-sm">The Address field is required</span>
                    </div>
                    <div class="input-item">
                        <label>City <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter your City" v-model="employee.city"/>
                        <span v-if="employeeErrors['city'] == true" class="text-danger text-sm">The City field is required</span>
                    </div>
                    <div class="input-item">
                        <label>State <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter your State" v-model="employee.state"/>
                        <span v-if="employeeErrors['state'] == true" class="text-danger text-sm">The State field is required</span>
                    </div>
                    <div class="input-item">
                        <label>Zip Code <span class="text-danger">*</span></label>
                        <input type="number" placeholder="Enter your ZipCode" v-model="employee.zipcode"/>
                        <span v-if="employeeErrors['zipcode'] == true" class="text-danger text-sm">The ZipCode field is required</span>
                    </div>
                </div>
            </div>
            <div class="step-buttons">
                <button class="previous-button" type="button" @click="previousStep">
                    Previous
                </button>
                <button class="continue-button" type="button" @click="nextStep">
                    Continue
                    <loading-icon :loading="loading"></loading-icon>
                </button>
            </div>
        </div>

        <div class="user-details-card width_min-card_user bg-blur btn_spcing-parent cstmCart" v-if="currentStep == 2">
            <div class="user-details-title">Review Your Order</div>
            <div class="responsive-custom-table">
                <div class="pricing-table minWidth_auto">
                        <div class="pricing-table-heading">Course Selection</div>
                </div>
                <div class="pricing-table calumn_width-three-grids" v-for="(item, index) in selectedItems" :key="index">
                <div class="course-name">
                    {{ item.name }}
                </div>
                <div class="price-div">
                    {{ formatPrice(item.price) }}
                </div>
                <div class="btn-div">
                    <button type="button" @click="removeCourse(item)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </div>

            <div class="pricing-table" v-if="discount == 0">
                <div class="course-name">
                    <div class="pricing-table-heading">Total Amount Due:</div>
                </div>
                <div class="price-div">{{ formatPrice(sub_total) }}</div>
               
            </div>
            <div class="pricing-table" v-if="discount !== 0">
                <div class="course-name">
                    Sub Total
                </div>
                <div class="price-div">{{ formatPrice(sub_total) }}</div>
            </div>
            <div class="pricing-table" v-if="discount !== 0">
                <div class="course-name">
                    Discount
                </div>
                <div class="price-div">{{ formatPrice(discount) }}</div>
            </div>
            <div class="pricing-table" v-if="discount !== 0">
                <div class="course-name">
                    <div class="pricing-table-heading">Total Amount Due:</div>
                </div>
                <div class="price-div">{{ formatPrice(total_amount) }}</div>
            </div>
            </div>
       

            <div class="btn_promo-one-line d-block">
                <div class="d-flex align-items-end  justify-content-md-center">
                    <div class="promocode mt-2 font-weight-bold d-flex flex-column">
                        <label>Promo Code</label>
                        <input type="text" placeholder="Enter your Promo Code" v-model="promocode"/>
                    </div>
                    <button class="continue-button greenBG cnt-btn promo-btn" type="button" @click="applyPromoCode">
                        Apply Promocode
                    </button>
                </div>
            </div>

            <div class="btn_promo-one-line">
                <div class="step-buttons">
                    <button class="previous-button" type="button" @click="previousStep">
                        Previous
                    </button>
                    <button class="continue-button" type="button" @click="signupAgreement">
                        Create Account
                        <loading-icon :loading="loading"></loading-icon>
                    </button>
                </div>
            </div>
        </div>

        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Terms and Conditions</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="individual"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount">
                        I Agree
                    </base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement">
                        Cancel
                    </base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" class="modal-title mb-0" style="color: #444c57">Payment</h4>
            <credit-card v-if="showPaymentOption" :address="employee.address" :city="employee.city" :monthlyAmount="creditCardMonthlyAmount" :onlySexualCourse="0" :state="employee.state" :yearlyAmount="creditCardAnnuallyAmount" :zip="employee.zipcode" type="individual" v-on:payClicked="payClicked"/>
        </modal>

        <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
        </modal>

        <the-footer/>
    </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import '@/orla/partials/style.css';
import '@/orla/partials/responsive.css';

import TheHeader from '@/orla/partials/Header.vue';
import TheFooter from '@/orla/partials/Footer.vue';
import Agreement from "@/views/Pages/Agreement.vue";
import CreditCard from "@/orla/partials/CreditCard";
import LoadingIcon from "@/orla/partials/LoadingIcon";
import FoodHandler from './FoodHandler.vue';

export default {
    components: {
        TheHeader,
        TheFooter,
        Agreement,
        CreditCard,
        LoadingIcon,
        FoodHandler,
    },
    data: function () {
        return {
            loading: false,
            paymentModalMessage: false,
            currentStep: 0,
            maximumStep: 3,
            already_member: false,
            member_id: "",
            employee: {
                company_name: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
                password: "",
            },
            employeeErrors: {
                first_name: false,
                last_name: false,
                email: false,
                phone: false,
                address: false,
                city: false,
                state: false,
                zipcode: false,
                password: false,
            },
            coursefolders: [],
            courses: [],
            i_agree: false,
            agreementModal: false,
            checked_courses: [],
            checked_coursefolders: [],
            checked_other_courses: [],
            sub_total: 0,
            showPaymentOption: false,
            selectedItems: [],
            discount: 0,
            total_amount: 0,
            promocode: "",
            creditCardMonthlyAmount: 0,
            creditCardAnnuallyAmount: 0,
        };
    },
    created: function () {
        this.getCourses();
    },
    methods: {
        previousStep: function () {
            if (this.currentStep == 0) {
                this.$router.push("/orla-signup");
                return false;
            }
            this.currentStep--;
        },
        nextStep: async function () {
            if ((this.currentStep + 1) > this.maximumStep) {
                return false;
            }

            if (this.currentStep == 1) {
                let hasError = this.performStepOneValidations();
                if (hasError) {
                    return Swal.fire({
                        title: "Error!",
                        html: "Fields marked with * are required",
                        icon: "error",
                        confirmButtonClass: "btn btn-success btn-fill",
                        confirmButtonText: "OK",
                    });
                }
                this.getSelectedItems();
                await this.calculatePricce();
            }

            this.currentStep++;
            this.loading = false;
        },
        acceptNumber: function () {
            var x = this.employee.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.employee.phone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        performStepOneValidations: function () {
            let hasError = false;
            Object.keys(this.employee).forEach((keyName) => {
                this.employeeErrors[keyName] = false;
                if (this.employee[keyName] == '' && keyName != "company_name") {
                    hasError = true;
                    this.employeeErrors[keyName] = true;
                }
            });
            return hasError;
        },
        getCourses: function () {
            this.$http.get("orla/discountRules").then(response => {
                for (let course of response.data.courses) {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        member_price: course.member,
                        non_member_price: course.non_member,
                        course_type: course.course_type,
                        cost: course.cost
                    };
                    this.courses.push(obj);
                }
                this.course = this.courses;
                for (let coursefolder of response.data.course_folders) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.coursefolders.push(obj1);
                }
            });
        },
        formatPrice: function (value) {
            return (
                "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
        },
        showAgreement: function () {
            if (this.employee.password == "") {
                return Swal.fire({
                    title: "Error!",
                    text: "Please enter password to continue.",
                    icon: "error"
                });
            }
            this.formData = {
                employee_company_name: this.employee.company_name ? this.employee.company_name : "individual",
                employee_first_name: this.employee.first_name,
                employee_last_name: this.employee.last_name,
                user_type: "individual",
                employee_address: this.employee.address,
                employee_city: this.employee.city,
                employee_state: this.employee.state,
                employee_zipcode: this.employee.zipcode,
                employee_email: this.employee.email,
                employee_phone_num: this.employee.phone,
                password: this.employee.password,
                address: this.employee.address,
                courses: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                i_agree: true
            };
            this.agreementModal = true;
        },
        applyPromoCode: async function () {
            await this.calculatePricce();
            this.loading = false;
        },
        calculatePricce: async function () {
            this.loading = true;
            await this.$http.post("orla/lead", {
                company_name: this.employee.company_name ? this.employee.company_name : "individual",
                first_name: this.employee.first_name,
                last_name: this.employee.last_name,
                number_of_locations: 1,
                number_of_employees: 1,
                email: this.employee.email,
                phone_num: this.employee.phone,
                user_type: "individual",
                already_member: this.already_member,
                member_id: this.member_id,
                course_ids: this.checked_courses,
                course_folders: this.checked_coursefolders,
                other_courses: this.checked_other_courses,
                isSexualHarrasment: this.isSexualHarrasment,
                promocode: this.promocode,
            }).then(resp => {
                this.sub_total = resp.data.sub_total;
                this.total_amount = resp.data.total_cost;
                this.discount = resp.data.discount ? resp.data.discount : 0;
                if (this.discount !== 0) {
                    this.creditCardMonthlyAmount = this.total_amount;
                    this.creditCardAnnuallyAmount = this.total_amount;
                } else {
                    this.creditCardMonthlyAmount = this.sub_total;
                    this.creditCardAnnuallyAmount = this.sub_total;
                }
            }).catch((error) => {
                this.loading = false;
                this.paymentModalMessage = false;
                return Swal.fire({
                    title: "Error!",
                    html: error.response.data.message,
                    icon: "error",
                });
            });
        },
        signupAgreement: function () {
            this.showAgreement();
        },
        finalCreateAccount: function () {
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        payClicked: function (cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: cardData.paymentType,
                cardholder_street_address:
                    cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token
            };

            if (payment.payment_type == "monthly") {
                payment.transaction_amount = this.creditCardMonthlyAmount.toFixed(2);
            }
            if (payment.payment_type == "yearly") {
                payment.transaction_amount = this.creditCardAnnuallyAmount.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.employee_address = cardData.address;
            this.formData.address = cardData.address;
            this.formData.employee_state = cardData.state;
            this.formData.employee_city = cardData.city;
            this.formData.employee_zipcode = cardData.zip;
            this.createAccount(this.formData);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.$http
                .post("orla/employee-register", formDataSubmitted)
                .then(resp => {
                    this.loading = false;
                    let ids = [];
                    let obj = {
                        id: resp.data.id
                    };
                    ids.push(obj);
                    this.showPaymentOption = false;
                    // this.$router.push("/orla-login");
                    Swal.fire({
                        title: "Success!",
                        html: `Your account has been created and is now active! <a href="https://lms.train321.com/#/orla-login">Click here</a> to Login`,
                        icon: "success",
                        confirmButton: "btn btn-success",
                        confirmButtonText: "Ok"
                    }).then(result => {
                        if (result.value) {
                            this.loading = false;
                            this.$router.push("/orla-login");
                        }
                    });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.loading = false
                        return Swal.fire({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error"
                        });
                    }
                });
        },
        getSelectedItems: function () {

            this.selectedItems = [];
            this.coursefolders.forEach(courseFolder => {
                if (this.checked_coursefolders.includes(courseFolder.id)) {
                    this.selectedItems.push({
                        name: courseFolder.coursefolder_name,
                        price: this.already_member ? courseFolder.member_price : courseFolder.non_member_price,
                        id: courseFolder.id,
                        type: 'courseFolder',
                    });
                }
            });
            this.courses.forEach(course => {
                if (this.checked_courses.includes(course.id)) {
                    this.selectedItems.push({
                        name: course.course_name,
                        price: this.already_member ? course.member_price : course.non_member_price,
                        id: course.id,
                        type: 'course'
                    });
                }
            });
        },

        removeCourse: async function (item) {
            this.loading = true;
            switch (item.type) {
                case  'courseFolder':
                    this.checked_coursefolders.forEach((courseFolder, index) => {
                        console.log(courseFolder);
                        console.log(index);
                        console.log(item.id);
                        if (courseFolder == item.id) {
                            this.checked_coursefolders.splice(index, 1);
                        }
                    });
                    break;
                case  'course':
                    this.checked_courses.forEach((course, index) => {
                        if (course == item.id) {
                            this.checked_courses.splice(index, 1);
                        }
                    });
                    break;
            }
            this.getSelectedItems();
            await this.calculatePricce();
            this.loading = false;
        }
    }
};
</script>